import logo from '@/images/logo-kenzie.svg';

import {
  StyledFooter,
  Container,
  Logo,
  ContentContainer,
  Title,
  SocialList,
  ContactList,
  Rights,
  Politics,
} from './styles';

type FooterProps = {
  bgColor?: string;
};

export default function Footer({ bgColor }: FooterProps) {
  return (
    <StyledFooter bgColor={bgColor}>
      <Container>
        <Logo src={logo.src} alt="Kenzie Academy Logo" width="64" height="64" />

        <ContentContainer>
          <div>
            <Title>KENZIE NA REDE</Title>

            <SocialList>
              <li>
                <a
                  href="https://www.facebook.com/kenzieacademybr/?utm_campaign=eventos&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/kenzieacademybr/?utm_campaign=eventos&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC6rcCbDzhVoIm1V7WnwPDIQ?sub_confirmation=1&utm_campaign=eventos&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  YouTube
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/school/kenzie-academy-brasil/?utm_campaign=eventos&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@kenzieacademy_br?utm_campaign=eventos&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  TikTok
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/kenzieacademybr?utm_campaign=eventos&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
            </SocialList>
          </div>

          <div>
            <Title>CONTATO</Title>

            <ContactList>
              <li>R. General Mario Tourinho, 1733 - 706 - Seminário, Curitiba/PR</li>
              <li>Avenida Efigênio Salles, 1299 - Aleixo, Manaus/AM</li>
              <li>CNPJ 35.224.825/0001-74</li>
              <li>
                <a href="mailto:contato@kenzie.com.br" rel="noreferrer">
                  contato@kenzie.com.br
                </a>
              </li>
            </ContactList>
          </div>
        </ContentContainer>
      </Container>

      <Rights>
        <Politics>
          <a href="https://inscricao.kenzie.com.br/termos-condicoes" rel="noreferrer" target={'_blank'}>
            Política de Privacidade
          </a>
        </Politics>
        <p>&copy; 2022 Kenzie Academy. All rights reserved.</p>
      </Rights>
    </StyledFooter>
  );
}
