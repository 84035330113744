import type { AppProps } from 'next/app';
import Global from '../styles/global';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Footer from '@/components/Footer';

const queryClient = new QueryClient();

export default function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NB5L2PM' });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} />
      <Footer />
      <Global />
    </QueryClientProvider>
  );
}
